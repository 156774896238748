<template>
  <div>
    <!-- Navigation -->
    <nav
      class="fixed flex justify-between items-center py-6 w-full lg:px-48 md:px-12 px-4 content-center bg-white z-10"
    >
      <div class="flex items-center">
        <img
          alt="Robotkong Booking System Logo"
          class="h-12 w-12 mr-1"
          src="../assets/logos/robotkonglogo.svg"
        />
        <span class="font-semibold text-lg"
          >Robot<span class="text-[#CF3C1B]">Kong</span></span
        >
      </div>
      <ul class="font-inter items-center hidden md:flex">
        <li class="mx-3">
          <a class="growing-underline" href="#howitworks">How it works</a>
        </li>
        <li class="growing-underline mx-3">
          <a href="#features">Features</a>
        </li>
        <li class="growing-underline mx-3">
          <a href="#pricing">Pricing</a>
        </li>
      </ul>
      <div class="font-inter flex flex-row items-center md:gap-2">
        <a href="/login">
          <RkButton variant="ghost" class="rounded-3xl">Login</RkButton>
        </a>
        <a href="/register">
          <RkButton class="rounded-3xl">Register</RkButton>
        </a>
        <div class="hidden md:block">
          <RkButton id="showMenu" variant="ghost" class="rounded-full">
            <img alt="Menu icon" src="../assets/logos/Menu.svg"
          /></RkButton>
        </div>
      </div>
    </nav>
    <div
      id="mobileNav"
      class="hidden px-4 py-6 fixed top-0 left-0 h-full w-full bg-secondary z-20 animate-fade-in-down"
    >
      <div id="hideMenu" class="flex justify-end">
        <img alt="" class="h-16 w-16" src="../assets/logos/Cross.svg" />
      </div>
      <ul class="font-inter flex flex-col mx-8 my-18 items-center text-3xl">
        <li class="my-6">
          <a href="#howitworks" @click="closeMenu">How it works</a>
        </li>
        <li class="my-6">
          <a href="#features" @click="closeMenu">Features</a>
        </li>
        <li class="my-6">
          <a href="#pricing" @click="closeMenu">Pricing</a>
        </li>
        <li class="my-6">
          <a href="#faq" @click="closeMenu">FAQ</a>
        </li>
        <hr class="w-full bg-white" />
        <li class="my-6">
          <a href="/register">Register</a>
        </li>
        <li class="my-6">
          <a href="/login">Log in</a>
        </li>
      </ul>
    </div>

    <!-- Hero -->
    <section
      class="pt-28 md:pt-[190px] md:mt-0 md:h-screen flex flex-col justify-center text-center md:text-left md:flex-row md:items-start lg:px-48 md:px-12 px-4"
    >
      <div class="md:flex-1 md:mr-24 md:max-w-[480px] pt-6 md:pt-0 lg:pt-14">
        <div class="flex flex-col justify-between">
          <div>
            <div class="md:max-w-[460px]">
              <h1
                class="font-pt-serif text-4xl md:text-5xl font-bold mb-7 md:leading-[60px]"
              >
                A tour booking system
                <!--    generated: #e2745c      #F5856C #E27258 -->
                <span
                  class="bg-underline1 bg-left-bottom bg-no-repeat pb-2 bg-100% text-[#E27258]"
                  >that's easy</span
                >
              </h1>
            </div>
            <p class="font-inter font-normal text-lg mb-7 mt-10 md:mt-0">
              Integrate the booking form, create tours, add schedules, easily
              import additional OTA bookings, check-in guests and walk-ins.
            </p>
            <!--            <div-->
            <!--              class="font-inter flex items-start justify-center md:justify-start gap-3"-->
            <!--            >-->
            <!--          <a href="/login">-->
            <!--            <RkButton variant="outline-heavy">Log in</RkButton>-->
            <!--          </a>-->
            <!--              <a href="/register" class="flex flex-col gap-1">-->
            <!--                <RkButton size="lg">Register</RkButton>-->
            <!--                <span class="text-xs px-2">Free trial, No credit card</span>-->
            <!--              </a>-->
            <!--            </div>-->
          </div>
          <div class="mt-6 md:mt-12 px-5 md:px-0">
            <!--            <div class="font-inter font-normal text-md">-->
            <!--              Used by tour companies around the world-->
            <!--            </div>-->
            <div class="flex justify-between mt-2 text-left">
              <div>
                <div class="text-2xl tracking-wide font-semibold">5</div>
                <span class="tracking-wide">Continents</span>
              </div>
              <div>
                <!--                With-->
                <div
                  class="text-[#CF3C1B] text-2xl tracking-wide font-semibold"
                >
                  78000+
                </div>
                <span class="tracking-wide">Bookings</span>
              </div>
              <div>
                <div
                  class="text-[#E27258] text-2xl tracking-wide font-semibold"
                >
                  60+
                </div>
                <span class="tracking-wide">Countries</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-around md:block mt-8 md:mt-0 md:flex-1">
        <div class="relative">
          <img
            alt=""
            class="absolute -top-16 -left-10"
            src="../assets/Highlight1.svg"
          />
        </div>
        <img alt="Booked" src="../assets/schedule2.svg" />
        <div class="relative">
          <img
            alt=""
            class="absolute -bottom-10 -right-6"
            src="../assets/Highlight2.svg"
          />
        </div>
      </div>
      <div id="howitworks"></div>
    </section>

    <!-- How it works -->
    <section class="bg-black text-white sectionSize">
      <div>
        <h2 class="secondaryTitle bg-underline2 bg-100%">How it works</h2>
      </div>
      <div class="flex flex-col md:flex-row">
        <div class="flex-1 mx-8 flex flex-col items-center my-4">
          <div
            class="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3"
          >
            1
          </div>
          <h3 class="font-inter font-medium text-xl mb-2">
            Add tours & events
          </h3>
          <p class="text-center font-inter">
            Add tours or events and create the schedules.
          </p>
        </div>
        <div class="flex-1 mx-8 flex flex-col items-center my-4">
          <div
            class="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3"
          >
            2
          </div>
          <h3 class="font-inter font-medium text-xl mb-2">
            Add our booking form
          </h3>
          <p class="text-center font-inter">
            Embed the form on your site (we'll help you do this).
          </p>
        </div>
        <div class="flex-1 mx-8 flex flex-col items-center my-4">
          <div
            class="border-2 rounded-full bg-secondary text-black h-12 w-12 flex justify-center items-center mb-3"
          >
            3
          </div>
          <h3 class="font-inter font-medium text-xl mb-2">Do the things</h3>
          <p class="text-center font-inter">
            Perform. You can use our bookings management & check-in system to
            help.
          </p>
        </div>
      </div>

      <div id="features"></div>
    </section>

    <!-- Features -->
    <section class="sectionSize">
      <div>
        <h2 class="secondaryTitle bg-underline3 bg-100%">Features</h2>
      </div>
      <div class="md:grid md:grid-cols-2 md:grid-rows-2">
        <div class="flex items-start font-inter my-6 mr-10">
          <img alt="" class="h-7 mr-4" src="../assets/logos/Heart.svg" />
          <div>
            <h3 class="font-semibold text-2xl">Tour & event scheduling</h3>
            <p>
              Add and manage the events, languages, schedules. Your settings
              like languages, seat count, max group size, locations, meeting
              instructions, blocking, et cetera can all be easily tweaked to
              your liking.
            </p>
          </div>
        </div>

        <div class="flex items-start font-inter my-6 mr-10">
          <img alt="" class="h-7 mr-4" src="../assets/logos/Heart.svg" />
          <div>
            <h3 class="font-semibold text-2xl">Accept bookings</h3>
            <p>
              We'll help you as much as needed to get our booking form well
              integrated into your website. Where guests can go to easily make
              bookings.
            </p>
          </div>
        </div>

        <div class="flex items-start font-inter my-6 mr-10">
          <img alt="" class="h-7 mr-4" src="../assets/logos/Heart.svg" />
          <div>
            <h3 class="font-semibold text-2xl">Manage bookings</h3>
            <p>
              You can see and search through all of the bookings on each date
              and time, make edits, or add new bookings directly, and see
              statistics on check-ins, external platforms, et cetera.
            </p>
          </div>
        </div>

        <div class="flex items-start font-inter my-6 mr-10">
          <img alt="" class="h-7 mr-4" src="../assets/logos/Heart.svg" />
          <div>
            <h3 class="font-semibold text-2xl">Check-ins</h3>
            <p>
              We have an easy to use check-in system that can be used to quickly
              check guests in at the event.
            </p>
          </div>
        </div>

        <div id="pricing"></div>
      </div>
    </section>

    <!-- Pricing -->
    <section class="sectionSize py-0">
      <div>
        <h2 class="secondaryTitle bg-underline4 mb-0 bg-100%">Pricing</h2>
      </div>
      <div class="flex w-full flex-col md:flex-row">
        <div
          class="flex-1 flex flex-col mx-6 shadow-2xl relative bg-white rounded-2xl py-5 px-8 my-8 md:top-24"
        >
          <h3 class="font-pt-serif font-normal text-2xl mb-4">Small scale</h3>
          <div class="font-inter font-bold text-2xl mb-4">
            $10
            <span class="font-normal text-base">/ month</span>
          </div>
          <div class="flex gap-3 flex-col">
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Up to 500 bookings per month</p>
            </div>
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Help integrating into your website</p>
            </div>
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>1 User login</p>
            </div>
          </div>

          <!--        <button class=" border-2 border-solid border-black rounded-xl text-lg py-3 mt-4">-->
          <!--          Choose plan-->
          <!--        </button>-->
        </div>

        <div
          class="flex-1 flex flex-col mx-6 shadow-2xl relative bg-white rounded-2xl py-5 px-8 my-8 md:top-12"
        >
          <h3 class="font-pt-serif font-normal text-2xl mb-4">Medium scale</h3>
          <div class="font-inter font-bold text-2xl mb-4">
            $40
            <span class="font-normal text-base">/ month</span>
          </div>
          <div class="flex gap-3 flex-col">
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Up to 2000 bookings per month</p>
            </div>
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Help integrating into your website</p>
            </div>
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>10 Team user logins</p>
            </div>
          </div>

          <!--        <button class=" border-2 border-solid border-black rounded-xl text-lg py-3 mt-4">-->
          <!--          Choose plan-->
          <!--        </button>-->
        </div>
        <div
          class="flex-1 flex flex-col mx-6 shadow-2xl relative bg-white rounded-2xl py-5 px-8 my-8 md:top-12"
        >
          <h3 class="font-pt-serif font-normal text-2xl mb-4">Large scale</h3>
          <div class="font-inter font-bold text-2xl mb-4">
            $65
            <span class="font-normal text-base">/ month</span>
          </div>
          <div class="flex gap-3 flex-col">
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Up to 3250 bookings per month</p>
            </div>
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Help integrating into your website</p>
            </div>
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Unlimited Team user logins</p>
            </div>
          </div>

          <!--        <button class=" border-2 border-solid border-black rounded-xl text-lg py-3 mt-4">-->
          <!--          Choose plan-->
          <!--        </button>-->
        </div>

        <div
          class="flex-1 flex flex-col mx-6 shadow-2xl relative bg-white rounded-2xl py-5 px-8 my-8 md:top-24"
        >
          <h3 class="font-pt-serif font-normal text-2xl mb-4">XL scale</h3>
          <div class="font-inter font-bold text-2xl mb-4">
            $100
            <span class="font-normal text-base">/ month</span>
          </div>
          <div class="flex gap-3 flex-col">
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Up to 7000 bookings per month</p>
            </div>
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Help integrating into your website</p>
            </div>
            <div class="flex">
              <img alt="" class="mr-1" src="../assets/logos/CheckedBox.svg" />
              <p>Unlimited Team user logins</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- FAQ  -->
    <section class="sectionSize items-start pt-8 md:pt-36 bg-black text-white">
      <div class="w-full text-center md:px-52">
        <div class="mb-4 text-lg">
          <a class="underline" href="mailto:info@robotkong.com"
            >Send us an email</a
          >
          and we can discuss a fair price based on your usage.
        </div>
        We want to help companies of all sizes, individual operators, small
        co-ops, countries with lower parity purchasing power, et cetera. So we
        offer varying levels of pricing and discounts based on your situation.
        We even allow free use of the product for non-profit organisations &
        groups of walking tour providers.
      </div>
      <div id="faq"></div>
      <div>
        <h2 class="secondaryTitle mb-0 mt-10 pl-0 bg-center bg-100%">FAQ</h2>
      </div>
      <div class="w-full py-4" toggleElement>
        <div class="flex justify-between items-center">
          <div class="font-inter font-medium mr-auto" question>
            How do you handle check-ins?
          </div>
          <img
            alt=""
            class="transform transition-transform"
            src="../assets/logos/CaretRight.svg"
          />
        </div>
        <div answer class="font-inter text-sm font-extralight pb-8 hidden">
          We have a checkin system which is mobile friendly and easy to search
          by name, email, and group sizes, and check people in. It also supports
          quick adding of walk-ins. The check-in statistics are also available
          to you.
        </div>
      </div>
      <hr class="w-full bg-white" />
      <div class="w-full py-4" toggleElement>
        <div class="flex justify-between items-center">
          <div class="font-inter font-medium mr-auto" question>
            Do you support multiple languages?
          </div>
          <img
            alt=""
            class="transform transition-transform"
            src="../assets/logos/CaretRight.svg"
          />
        </div>
        <div answer class="font-inter text-sm font-extralight pb-8 hidden">
          Indeed! All of your event details can be added in different languages
          and can be selected when adding the event schedule. The user will then
          be able to select the language when booking. You can also use
          different versions of the booking forms on pages in your website to
          automatically show a preferred language (i.e. your spanish page can
          show the event details in spanish on the booking form).
        </div>
      </div>
      <hr class="w-full bg-white" />
      <div class="w-full py-4" toggleElement>
        <div class="flex justify-between items-center">
          <div class="font-inter font-medium mr-auto" question>
            Can I get an overview of all bookings?
          </div>
          <img
            alt=""
            class="transform transition-transform"
            src="../assets/logos/CaretRight.svg"
          />
        </div>
        <div answer class="font-inter text-sm font-extralight pb-8 hidden">
          We have a searchable overview of all bookings (which you can edit),
          and you can also view statistics of where the bookings originated, how
          many checked in, percentages of direct vs external bookings, etc. All
          easily searchable by name, email, group size, platform, etc.
        </div>
      </div>
      <hr class="w-full bg-white" />
      <div class="w-full py-4" toggleElement>
        <div class="flex justify-between items-center">
          <div class="font-inter font-medium mr-auto" question>
            What about paid tours and events?
          </div>
          <img
            alt=""
            class="transform transition-transform"
            src="../assets/logos/CaretRight.svg"
          />
        </div>
        <div answer class="font-inter text-sm font-extralight pb-8 hidden">
          You can add price & currency to tours and events. We integrate with
          Stripe to allow guests to pay directly when booking. If you dont use
          stripe, we also allow you to use a payment link.
        </div>
      </div>
      <hr class="w-full bg-white" />
      <div class="w-full py-4" toggleElement>
        <div class="flex justify-between items-center">
          <div class="font-inter font-medium mr-auto" question>
            How do you add events to the booking form / calendar?
          </div>
          <img
            alt=""
            class="transform transition-transform"
            src="../assets/logos/CaretRight.svg"
          />
        </div>
        <div answer class="font-inter text-sm font-extralight pb-8 hidden">
          You can select tours/events to add, select language options, time(s)
          of day, day(s) of week, within ranges of dates and bulk add events.
          You may also add individual tours/events at specific dates or times.
        </div>
      </div>
      <hr class="w-full bg-white" />
      <div class="w-full py-4" toggleElement>
        <div class="flex justify-between items-center">
          <div class="font-inter font-medium mr-auto" question>
            Do you charge a per-booking fee or commission?
          </div>
          <img
            alt=""
            class="transform transition-transform"
            src="../assets/logos/CaretRight.svg"
          />
        </div>
        <div answer class="font-inter text-sm font-extralight pb-8 hidden">
          No way! We don't charge any per-booking fees or commissions. We just
          have a fixed monthly fee, and even that is optional if you don't get
          any bookings in a month.
        </div>
      </div>
      <hr class="w-full bg-white" />
      <div class="w-full py-4" toggleElement>
        <div class="flex justify-between items-center">
          <div class="font-inter font-medium mr-auto" question>
            Do you have any cool features on the horizon?
          </div>
          <img
            alt=""
            class="transform transition-transform"
            src="../assets/logos/CaretRight.svg"
          />
        </div>
        <div answer class="font-inter text-sm font-extralight pb-8 hidden">
          Absolutely! We're actively developing a range of exciting features,
          including a complimentary <strong>OTA</strong>-style platform on our
          site. This allows users to discover your events and tours, seamlessly
          booking them directly through our platform. There will be no
          additional fees or charges associated, as long as you're a user of our
          booking system. The primary goal is to help you get more bookings. We
          also have easier capabilities for paid and private tours/events in the
          works, and a range of other features being worked on.
        </div>
      </div>
      <hr class="w-full bg-white" />
      <div class="w-full py-4" toggleElement>
        <div class="flex justify-between items-center">
          <div class="font-inter font-medium mr-auto" question>
            Can my team have their own logins?
          </div>
          <img
            alt=""
            class="transform transition-transform"
            src="../assets/logos/CaretRight.svg"
          />
        </div>
        <div answer class="font-inter text-sm font-extralight pb-8 hidden">
          Yes! You can add multiple users for each of your team members and they
          can all have their own logins and permissions to view and manage
          bookings, check-ins, etc. We even offer another tier which is
          'organisations' of tour/event companies that can have their own
          central booking forms which associate the bookings with the
          organisation. And the organisation can also have multiple users to
          manage it. Even while the individual companies can have separately
          owned booking forms unrelated to the organisations of which they are
          members.
        </div>
      </div>
      <hr class="w-full bg-white" />

      <!--    <div class="w-full py-4" toggleElement>-->
      <!--      <div class="flex justify-between items-center">-->
      <!--        <div class="font-inter font-medium mr-auto" question>-->
      <!--          How do we add the booking form to our site-->
      <!--        </div>-->
      <!--        <img alt="" class="transform transition-transform" src='../assets/logos/CaretRight.svg'/>-->
      <!--      </div>-->
      <!--      <div answer class="font-inter text-sm font-extralight pb-8 hidden">-->
      <!--        Yes! No doubt about it.-->
      <!--      </div>-->
      <!--    </div>-->
      <hr class="w-full bg-white" />
    </section>

    <!-- Footer -->
    <section class="bg-black sectionSize">
      <div class="mb-4 text-white">&diams; ROBOTKONG</div>
      <!--      <div class="flex mb-8">-->
      <!--        <a href="#">-->
      <!--          <img alt="Facebook logo" class="mx-4" src='../assets/logos/Facebook.svg'/>-->
      <!--        </a>-->
      <!--        <a href="#">-->
      <!--          <img alt="Youtube logo" class="mx-4" src='../assets/logos/Youtube.svg'/>-->
      <!--        </a>-->
      <!--        <a href="#">-->
      <!--          <img alt="Instagram logo" class="mx-4" src='../assets/logos/Instagram.svg'/>-->
      <!--        </a>-->
      <!--        <a href="#">-->
      <!--          <img alt="Twitter logo" class="mx-4" src='../assets/logos/Twitter.svg'/>-->
      <!--        </a>-->
      <!--      </div>-->
      <div class="text-white font-inter text-sm">
        © 2024 ROBOTKONG. All rights reserved
      </div>
      <div class="text-white font-inter text-sm"><a href="/all">Tours</a></div>
    </section>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  layout: 'blank',
})
useHead({
  title: 'Robotkong booking system',
  meta: [
    {
      name: 'description',
      content: "Robotkong | A booking system that's easy",
    },
  ],
})
useSeoMeta({
  title: "Robotkong | A booking system that's easy",
  ogTitle: "Robotkong | A booking system that's easy",
  description:
    'A booking system for tours and events allowing you to manage bookings, check-ins, and include a booking form on your website.',
  ogDescription:
    'A booking system for tours and events allowing you to manage bookings, check-ins, and include a booking form on your website.',
  ogImage: '/robotkonglogo.svg',
})

onMounted(() => {
  document
    .querySelector('#showMenu')
    .addEventListener('click', function (event) {
      document.querySelector('#mobileNav').classList.remove('hidden')
    })

  document
    .querySelector('#hideMenu')
    .addEventListener('click', function (event) {
      document.querySelector('#mobileNav').classList.add('hidden')
    })

  document.querySelectorAll('[toggleElement]').forEach((toggle) => {
    toggle.addEventListener('click', function (event) {
      const answerElement = toggle.querySelector('[answer]')
      const caretElement = toggle.querySelector('img')
      if (answerElement.classList.contains('hidden')) {
        answerElement.classList.remove('hidden')
        caretElement.classList.add('rotate-90')
      } else {
        answerElement.classList.add('hidden')
        caretElement.classList.remove('rotate-90')
      }
    })
  })
})

const closeMenu = () => {
  document.querySelector('#mobileNav').classList.add('hidden')
}
</script>
<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sectionSize {
    @apply lg:px-48 md:px-12 px-4 py-14 flex flex-col items-center;
  }

  .secondaryTitle {
    @apply font-pt-serif text-3xl bg-left-bottom bg-no-repeat pb-8 px-16 mb-8;
  }
}

.growing-underline {
  display: block;
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;
}

.growing-underline::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: black;
  transition:
    opacity 300ms,
    transform 300ms;
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.growing-underline:hover::after,
.growing-underline:focus::after {
  transform: translate3d(0, 0, 0);
}
</style>
